$azulMiFortaleza: #2885FF;
$amarilloMiFortaleza: #EBD238;
$gris0: #262626;
$gris5: lighten($gris0,25%);
$gris10: lighten($gris0,50%);
$gris11: lighten($gris0,60%);
$blanco: #FFF;
$negro: #000;
$magenta: #DE1669;
$verde: #1ECB5C;
$fondoDarkMode: #1c202e;
$primaryDarkMode: lighten($fondoDarkMode,15%);
$primaryDark: #15a099;;
$lightPurple: #445383;

$fontSizes: (
    "Header": 4.318rem,
    "Header-Mobile": 3.318rem,
    "H1": 2.318rem,
    "H2": 1.3rem,
    "H3": 1.318rem,
    "P": 1rem
);

$fontWeights: (
    "Bold": 900,
    "Normal": 500,
    "Light": 100
);

$line-height: 1.6;
$line-height-titles: 1.2;

@function fontWeight($weight){
    @return map-get($fontWeights, $weight)
};
    
@function fontSize($size){
    @return map-get($fontSizes, $size)
};
