@import "../sass/mediaQueries";
@import "../sass/themes";
@import "../sass/vars";

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blanco;
  @include darkTheme {
    background-color: $fondoDarkMode;
  }
}

.container {
  display: grid;
  height: 100vh;
  width: 100vw;
  margin: auto auto;

  grid-template-columns: 1fr;
  grid-template-rows: 35vh auto 4.5vh 2.5vh 35vh;
  grid-row-gap: 2vh;

  grid-template-areas:
    "top"
    "img"
    "msj"
    "loader"
    "bottom";
}

.company-logo {
  grid-area: img;
  max-width: 50vw;
  display: block;
  margin: auto auto 5vh auto;

  @include desktopView {
    max-width: 30vw;
  }
  @include tabletView {
    max-width: 30vw;
  }
  @include tabletPortraitView {
    max-width: 30vw;
  }
}

.msj-loading {
  grid-area: msj;
  @extend .encabezado;
  justify-self: center;
  color: $gris0;

  @include darkTheme {
    color: lighten($fondoDarkMode, 55%);
  }
}

.lds-ellipsis {
  grid-area: loader;
}

.lds-ellipsis div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-left: calc(50% - 33px);
  background-color: $azulMiFortaleza;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  @include darkTheme {
    background-color: lighten($fondoDarkMode, 55%);
  }
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
  z-index: 2;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 46px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
