@import "../sass/loading";
@import "../sass/resets";
@import "../sass/themes";
@import "../sass/vars";
@import "../sass/mediaQueries";
@import "../sass/modals";

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.modal-pasos-content {
  @extend .modal;
  text-align: center;

  & > .titulo {
    text-align: left;
  }
}

.img-nav-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  & > i {
    font-size: fontSize("H1");
    color: $gris11;
    display: none;

    &:hover {
      color: $gris5;
    }
  }

  & > img {
    margin: 3vh 0vw;
    max-height: 45vh;
    max-width: 75vw;
  }

  @include desktopView {
    justify-content: space-between;

    & > img {
      min-height: 17vh;
      min-width: 27vw;
    }

    & > i {
      display: block;
    }
  }

  @include darkTheme {
    & > i {
      color: lighten($fondoDarkMode, 55%);

      &:hover {
        color: #3c497c;
      }
    }
  }
}

#descripcion-pasos {
  & > .encabezado {
    margin-top: 5vh;
    margin-bottom: 1rem;
  }
}

.pager-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  & > div {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 0.5rem;

    background-color: $gris11;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    @include darkTheme {
      background-color: lighten($fondoDarkMode, 55%);
    }
  }
}
