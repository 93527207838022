/* Phones */
@mixin phoneView{

    @media (max-width: 34em){
      @content;
    }
    
}

@mixin smallPhoneView{

    @media only screen 
    and (min-device-width: 320px) 
    and (-webkit-min-device-pixel-ratio: 2) {
        @content;
  }
}

@mixin phoneLandscapeView{
        
    @media (min-width: 34em) and  (orientation: landscape) {
        @content;
    }
}

/* Tablets */
@mixin tabletView{

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {
        @content;
  }
    
}

@mixin tabletPortraitView{
    
    @media (min-width: 50em) and (orientation: portrait){
        @content;
    }

}
    
/* Desktop */
@mixin desktopView{
    
    @media screen 
    and (min-width: 1200px) 
    and (-webkit-min-device-pixel-ratio: 1) { 
        @content;
  }

}