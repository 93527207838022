@import "../sass/mediaQueries";
@import "../sass/vars";
@import "../sass/themes";

.modal {
  background-color: #fefefe;
  margin: auto;
  padding: 30px;
  border: 1px solid #888;
  border-radius: 3vh;
  width: 75%;
  transition: display 0s, opacity 0.5s linear;

  @include desktopView {
    width: 50%;
  }

  @include darkTheme {
    background-color: $fondoDarkMode;
    border: 1px solid $gris0;
  }
}

.modal-bg {
  display: none; /* Hidden by default */
  align-content: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

.mdl-close {
  color: $gris10;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $magenta;
    text-decoration: none;
    cursor: pointer;
  }
}
